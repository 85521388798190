import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);
const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);

export const sbkSearchSuggestionStyle = css`
    .search-suggestion-list__wrapper {
        padding-top: calc(var(--sbkSearchFlyoutSpacingViewportXlGap) - var(--sbkCoreSpacingMd));

        @media all and (max-width: ${breakpointXl}) {
            padding-top: calc(var(--sbkSearchFlyoutSpacingViewportLgGap) - var(--sbkCoreSpacingMd));
        }
        @media all and (max-width: ${breakpointLg}) {
            padding-top: calc(var(--sbkSearchFlyoutSpacingViewportMdGap) - var(--sbkCoreSpacingMd));
        }
        @media all and (max-width: ${breakpointMd}) {
            padding-top: calc(var(--sbkSearchFlyoutSpacingViewportSmGap) - var(--sbkCoreSpacingMd));
        }
    }

    .search-suggestion-list {
        margin: 0;
        padding: var(--sbkSearchFlyoutSpacingViewportXlGap) 0 0 0;

        @media all and (max-width: ${breakpointXl}) {
            padding: var(--sbkSearchFlyoutSpacingViewportLgGap) 0 0 0;
        }
        @media all and (max-width: ${breakpointLg}) {
            padding: var(--sbkSearchFlyoutSpacingViewportMdGap) 0 0 0;
        }
        @media all and (max-width: ${breakpointMd}) {
            padding: var(--sbkSearchFlyoutSpacingViewportSmGap) 0 0 0;
        }
    }

    h3 {
        margin: 0;
        font: var(--sbkSearchFlyoutTypographyTitleLargeScr);
        @media all and (max-width: ${breakpointMd}) {
            font: var(--sbkSearchFlyoutTypographyTitleSmallScr);
        }
    }
`;
