import {customElement, property} from 'lit/decorators.js';
import {html, LitElement} from 'lit';
import {sbkSearchSuggestionStyle} from "@/components/search-suggestion-list/search-suggestion-list.styles";


@customElement('sbk-search-suggestion-list')
export class SbkSearchSuggestionList extends LitElement {

    @property({type: String, attribute: 'list-title'})
    listTitle = '';

    static get styles() {
        return sbkSearchSuggestionStyle;
    }

    render() {
        return html`
            <div
                class="search-suggestion-list__wrapper">
                <h3>${this.listTitle}</h3>
                <ul class="search-suggestion-list">
                    <slot></slot>
                </ul>
            </div>
        `;
    }
}
